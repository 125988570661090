.App {
  text-align: center;
}
#root {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#navbar {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.ta-r {
  right: 0;
}

.jc-fe {
  justify-content: flex-end;
}

.d-f {
  display: flex;
}

.ta-c {
  text-align: center;
  vertical-align: middle;
}

span.error-text {
  color: red;
}

.testStick {
  position: sticky;
  top: 0;
  background-color: black;
}

.loader {
  width: 100%;
  height: 90%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 1;
  z-index: 5;
  left: 0;
}

.loader img {
  max-width: 210px;
}

.bgc-red {
  background-color: #ff7300b6;
}